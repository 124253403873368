import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// React Bootstrap
import { Container, Row, Col } from "react-bootstrap"

const SecondPage = () => (
  <Layout>
    <Seo title="Contact Success" />
    
    <Container className="my-4 singleCol">
      <h1>Call or Email Today to Schedule a FREE Consultation</h1>
      <Row>
        <Col lg={6}>
          <p className="mt-1 mb-0">Phone: 415-825-0350 (9am-6pm Daily)</p>
          <p>Email: <a href="mailto:Jim.Kautz@comcast.net">Jim.Kautz@comcast.net</a></p>
        </Col>
        <Col lg={6}>
          <p>Thank you. Jim will get back to you shortly.</p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default SecondPage
